// Imports
// ------------
import styled, { css } from 'styled-components';
import { Span } from '@tackl';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------------
export const Label = styled(Span)(props => css`
    transition: all .3s ${props.theme.bezzy};
    margin-right: 1.2rem;

    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.05em;
`);

export const ButtonJacket = styled.button(props => css`
    transition: all .3s ${props.theme.bezzy};
    position: relative;
    display: flex;
    flex-direction: row;
    pointer-events: ${props.disabled ? `none` : `all`};

    padding: 1.6rem 2.4rem;
    width: auto;
    min-width: max-content;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3.6rem;
    opacity: ${props.disabled ? 0.4 : 1};
    
    &:before {
        transition: all .3s ${props.theme.bezzy};
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;

        border: 1px dashed ${props.theme.bc2};
        border-radius: 3.6rem;
        padding: 2px;
    }

    svg {
        transition: all .6s ${props.theme.bezzy};
        fill: ${props.disabled ? props.theme.bc2 : props.theme.bc1};
    }

    ${Label} {
        color: ${props.disabled ? props.theme.bc2 : props.theme.white};
    }

    &:hover {
        border-color: ${props.theme.bc1};
        background: ${props.theme.bc1};
        cursor: none;

        &:before {
            opacity: 0;
        }

        svg {
            fill: ${props.theme.bc3};
            transform: translateX(.6rem);
        }

        ${Label} {
            color: ${props.theme.bc3};
        }
    }
`);

const buttonLinks = (props) => css`
    transition: all .3s ${props.theme.bezzy};
    position: relative;
    display: inline-flex;
    flex-direction: row;
    pointer-events: ${props.disabled ? `none` : `all`};

    padding: 1.6rem 2.4rem;
    width: auto;
    min-width: max-content;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 3.6rem;
    opacity: ${props.disabled ? 0.4 : 1};

    &:before {
        transition: all .3s ${props.theme.bezzy};
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;

        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%2378674DFF' stroke-width='3' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
        border-radius: 3rem;
    }

    svg {
        transition: all .6s ${props.theme.bezzy};
        fill: ${props.disabled ? props.theme.bc2 : props.theme.bc1};
    }

    ${Label} {
        color: ${props.disabled ? props.theme.bc2 : props.theme.white};
    }

    &:hover {
        border-color: ${props.theme.bc1};
        background: ${props.theme.bc1};

        &:before {
            opacity: 0;
        }

        svg {
            fill: ${props.theme.bc3};
            transform: translateX(.6rem);
        }

        ${Label} {
            color: ${props.theme.bc3};
        }
    }
`;

export const HashJacket = styled.a((props) => css`
    ${buttonLinks}
`);

export const LinkJacket = styled(TransitionLink)((props) => css`
    ${buttonLinks}
`);