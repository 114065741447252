// Imports
// ------------
import React, { useRef, useState, useEffect } from 'react';
import Input from '@parts/Input';
import Button from '@parts/Button';
import axios from 'axios';
// import SpamTestImg from '@images/math-test.png';
import ReCAPTCHA from "react-google-recaptcha";
import { Row, Column } from '@waffl';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

// Styles
// ------------
import { Jacket, Text, FormJacket, ButtonJacket, Response, Content } from './styles';

// Component
// ------------
const ContactForm = ({ formSuccessMessage, formErrorMessage }) => {
    // NOTE • Recaptcha
    const captchaKey = process.env.SITE_RECAPTCHA_KEY;

    // NOTE • MIXPANEL
	const mixpanel = useMixpanel();

    // NOTE • Refs
    // ------
    const nameField = useRef(null);
    const emailField = useRef(null);
    // const spamField = useRef(null);
    const recaptchaRef = useRef(null);

    // NOTE • States
    // ------
    const [formData, setFormData] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailTest, setEmailTest] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);
	const [responseGood, setResponseGood] = useState(false);
    const [responseError, setResponseError] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    // NOTE • Regex
    // ------
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    // NOTE • Handle Input Change
    // ------
    const handleChange = (e) => {
        if (e.target.type === "email") {
            setEmailTest({ value: e.target.value });
            const test = validEmailRegex.test(emailTest.value);
            setEmailError(!test);
        }

        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleBlur = (e) => {
        if (e.target.type === "email") {
            setEmailTest({ value: e.target.value.trim().toLowerCase() });
            const test = validEmailRegex.test(emailTest.value);
            setEmailError(!test);
        }

        if(!emailError || e.target.value.length == 0) {
            setEmailError(false);
        }
    }

    // NOTE • Periodically correct the email validation
    // ------
    useEffect(() => {
        setTimeout(() => {
            if (emailField.current && emailField.current.matches(':-webkit-autofill')) {
                setEmailTest({ value: emailField.current.value.trim().toLowerCase() });
                const test = validEmailRegex.test(emailTest.value);
                setEmailError(!test);
            }
        }, 1000);
    });

    const handleRecaptcha = (e) => {
        setCaptchaToken(e);
        setButtonDisabled(false);
    }

    // NOTE • Handle Form Submission
    // ------
    const handleSubmit = (e) => {
        e.preventDefault();

        function encode(data) {
			return Object.keys(data)
				.map(
					(key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
				)
				.join('&');
		}

        const axiosOptions = {
			url: window.location.href,
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			data: encode({
                'form-name': e.target.getAttribute('name'),
                'g-recaptcha-response': captchaToken,
                ...formData
            }),
		};
        
        axios(axiosOptions)
            .then((response) => {
                console.log(response);
                setResponseGood(true);

                mixpanel.track(`Contact form submitted`);
    
                setTimeout(() => {
                    setFormData('');
                    setEmailError(false);
                    setEmailTest('');
                }, 3000);
    
                setTimeout(() => {
                    setResponseGood(false);
                }, 4000);
            })
            .catch((err) => {
                setResponseError(true);
    
                setTimeout(() => {
                    setFormData('');
                    setEmailError(false);
                    setEmailTest('');
                }, 3000);
    
                setTimeout(() => {
                    setResponseError(false);
                }, 4000);
            });
    }

    return (
        <Jacket pad>
            <Row isExpanded isCollapsed isOuter>
                <Column small={12} medium={10} pushMedium={1}>
                    <Row isExpanded>
                        <Column small={12} medium={4} xlarge={3} mpad>
                            <Text>Whether you’ve got questions about photography, want to ask about a shop item or an experience day with me, I’m all ears, ask away using this form and I’ll respond as quickly as possible!</Text>
                        </Column>
                        <Column small={12} medium={7} pushMedium={1} xlarge={8}>
                            
                            {responseGood || responseError ? (
                                <Response good={responseGood} bad={responseError}>
                                    <Content>
                                        {responseGood ? formSuccessMessage : formErrorMessage}
                                    </Content>
                                </Response>
                            ) : (
                                <FormJacket
                                    onSubmit={handleSubmit}
                                    name="Photoscoper: Contact"
                                    method="POST"
                                    data-netlify="true"
                                    // netlify-honeypot="bot-field"
                                    data-netlify-recaptcha="true"
                                >
                                    {/* <input type='hidden' name='bot-field' /> */}
                                    <input type='hidden' name='form-name' value='Photoscoper: Contact' />

                                    <Row isExpanded>
                                        <Column small={12} large={6}>
                                            <Input
                                                ref={nameField}
                                                isRequired
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label='Name*'
                                                type='text'
                                                name='name'
                                                value={formData.name ? formData.name : ''}
                                            />
                                        </Column>
                                        <Column small={12} large={6}>
                                            <Input
                                                ref={emailField}
                                                isRequired
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label='Email*'
                                                type='email'
                                                name='email'
                                                emailError={emailError}
                                                value={formData.email ? formData.email : ''}
                                            />
                                        </Column>
                                    </Row>

                                    <Row isExpanded>
                                        <Column small={12}>
                                            <Input
                                                isBig
                                                isRequired
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                label='Your message*'
                                                type='textarea'
                                                name='message'
                                                value={formData.message ? formData.message : ''}
                                            />
                                        </Column>
                                    </Row>

                                    <Row isExpanded>
                                        <Column small={12}>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                id="recaptcha-google"
                                                size="normal"
                                                theme="dark"
                                                sitekey={captchaKey}
                                                onChange={(e) => handleRecaptcha(e)}
                                            />
                                        </Column>
                                    </Row>

                                    <Row isExpanded>
                                        <Column small={12}>
                                            <ButtonJacket>
                                                <Button
                                                    isBtn
                                                    isNext
                                                    label='Send'
                                                    disabled={buttonDisabled}
                                                />
                                            </ButtonJacket>
                                        </Column>
                                    </Row>
                                </FormJacket>
                            )}

                        </Column>
                    </Row>
                </Column>
            </Row>
        </Jacket>
    );
}

export default ContactForm;
