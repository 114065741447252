// Imports
// ------
import React, { useState } from 'react';
import { action } from 'mobx';

// Styles
// ------
import {
    Jacket,
    Label,
    Error,
} from './styles';

// Component
// ------
const Input = React.forwardRef((props, ref) => {
    const { label, type, name, id, onChange, onBlur, value, emailError, className, isBig, isRequired } = props;

    const [isFocus, setIsFocus] = useState(false);


    // NOTE • Functions
    const handleFocus = action((e) => {
        if(e.target.value.length >= 1) {
            setIsFocus(true);
        } else {
            setIsFocus(false);

            return onBlur;
        }
    });

    const handleBlur = action((e) => {
        if(e.target.value.length === 0) {
            setIsFocus(false);
        }

        return props.onBlur(e);
    });
    
    return (
        <Jacket className={className} isBig={isBig} isFocus={isFocus} isRequired={isRequired}>
            <Label isFocus={isFocus}>{label}</Label>

            {type === "textarea" && (
                <textarea name={name}  onInput={(e) => handleFocus(e)} onChange={onChange} value={value} onFocus={(e) => handleFocus(e)} onBlur={(e) => handleBlur(e)} />
            )}

            {(type === "text" || type === "email") && (
                <input ref={ref && ref} type={type} name={name} id={id} onInput={(e) => handleFocus(e)} onChange={onChange} value={value} onFocus={(e) => handleFocus(e)} onBlur={(e) => handleBlur(e)} />
            )}

            {emailError && <Error>Email is not valid!</Error>}
        </Jacket>
    );
});

export default Input;
