// Imports
// ------------
import React, { useEffect } from 'react';
import Icon from '@icons';
import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { ButtonJacket, HashJacket, LinkJacket, Label } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Button = ({ isBtn, isPrev, isNext, isHash, isLink, href, to, label, disabled, onClick }) => {
    let bodyScrollBar;
    
    // NOTE • Handle hash links
    useEffect(() => {
        const scroller = document.querySelector('#scroller');
        bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

        ScrollTrigger.scrollerProxy(".scroller", {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });
    
        bodyScrollBar.addListener(ScrollTrigger.update);
    }, []);

    const handleHash = (e) => {
        e.preventDefault();

        let link = document.querySelector(href);
        let scrollHere = link.offsetTop;

        bodyScrollBar.scrollTo(0, scrollHere, 1000);
    }
	
    if(isBtn) return (
        <ButtonJacket disabled={disabled} onClick={onClick} className="chb">
            <Label>{label}</Label>
            {isNext && <Icon type="right" />}
        </ButtonJacket>
    );
    
    if(isHash) return (
        <HashJacket href={href} onClick={(e) => handleHash(e)} className="chb">
            <Label>{label}</Label>
            {isNext && <Icon type="right" />}
        </HashJacket>
    );
    
    if(isLink) return (
        <LinkJacket to={to} className="chb">
            <Label>{label}</Label>
            {isNext && <Icon type="right" />}
        </LinkJacket>
    );
}

export default Button;