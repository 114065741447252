// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const FooterLogo = ({ ref, isBig }) => (
    <Jacket isBig={isBig} to="/">
        <Icon type="brand" />
    </Jacket>
);

export default FooterLogo;