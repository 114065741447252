// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket, List, Item, Link } from './styles';

// Component
// ------------
const FooterMenu = () => {
    return (
        <Jacket>
            <List>
                <Item>
                    <Link to="/galleries">Galleries</Link>
                </Item>
                <Item>
                    <Link to="/experiences">Experiences</Link>
                </Item>
                <Item>
                    <Link to="/dog-photography">Dog Photoshoots</Link>
                </Item>
                <Item>
                    <Link to="/contact">Contact</Link>
                </Item>
            </List>
        </Jacket>
    );
}

export default FooterMenu;