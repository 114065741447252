// Imports
// ------
import styled, { css } from 'styled-components';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------
export const Jacket = styled(TransitionLink)(props => css`
    transition: all 1.2s ${props.theme.bezzy};

    svg {
        width: 6rem;
        height: 6rem;

        ${props.isBig && css`
            pointer-events: none;

            width: 100vw;
            height: auto;

            opacity: .02;

            path {
                fill: ${props.theme.bc2} !important;
            }
        `}
    }
`);