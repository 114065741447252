// Imports
// ------------
import styled, { css } from 'styled-components';
import { breakup, Nav } from '@tackl';
import { TransitionLink } from 'gatsby-plugin-transitions';

// Exports
// ------------
export const Jacket = styled(Nav)`
    margin-top: 3.6rem;
`;

export const List = styled.ul(props => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakup.medium` flex-direction: row; `}
`);

export const Item = styled.li(props => css`
    position: relative;
    padding: 1.2rem 0;

    ${breakup.medium`
        padding: 0 2.4rem;

        &:after {
            content: '';
            position: absolute;
            top: 50%; right: 0;
            transform: translateY(-50%);

            width: 3px;
            height: 3px;
            background: ${props.theme.bc1};
            border-radius: 100%;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;

            &:after { content: none; }
        }
    `}
`);

export const Link = styled(TransitionLink)(props => css`
    position: relative;
    transition: all .6s ${props.theme.bezzy};

    font-family: ${props.theme.heading};
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;

    color: ${props.theme.bc1};

    &:visited {
        color: ${props.theme.bc1};
    }

    &:before {
        content: '';

        transition: all .6s ${props.theme.bezzy};
        position: absolute;
        bottom: -2px; left: 0;

        width: 0;
        height: 1px;
        background: ${props.theme.white};
    }

    &:hover {
        color: ${props.theme.white};

        &:before {
            width: 100%;
        }
    }
`);