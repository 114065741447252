// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Fieldset, Span } from '@tackl';
import { pStyles } from '@tackl-type';

// Exports
// ------
export const Label = styled.label(
    (p) => css`
        ${pStyles}

        font-size: 1.3rem !important;
        font-weight: 500;
        text-transform: uppercase;
        color: ${p.isFocus ? p.theme.bc1 : p.theme.bc5};

        pointer-events: none;
        transition: all .4s ${p.theme.bezzy};
        transform: translateY(${p.isFocus ? `0rem` : `3rem`});

        width: 80%;
    `
);

export const Jacket = styled(Fieldset)((p) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    
    width: 100%;
    min-width: 15rem;
    margin-bottom: 1.2rem;

    ${breakup.medium` margin-bottom: 2.4rem; `}

    > input, > textarea {
        display: flex;
        border-bottom: 1px solid ${p.theme.lightgrey} !important;
        background: transparent;
        padding: .8rem 0;

        ${pStyles}
        font-size: 1.6rem !important;
        font-weight: 500;

        &:focus {
            border-color: ${p.theme.bc1} !important;
            outline: none;
        }

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px ${p.theme.bc3} inset;
            -webkit-text-fill-color: ${p.theme.white};
            border-color: ${p.theme.white} !important;
        }
    }

    textarea {
        ${p.isBig && css`
            height: 15rem;
        `}
    }

    ${p.isRequired && css`
        &:after {
            content: 'Required';
            position: absolute;
            top: 3.6rem; right: 0;

            font-family: ${p.theme.heading};
            font-size: 1.3rem;
            font-weight: 500;
            text-transform: uppercase;
            color: ${p.theme.bc2};
            
            transition: all .3s ${p.theme.bezzy};
            opacity: ${p.isFocus ? 0 : 1};
        }
    `}
`);

export const Error = styled(Span)((p) => css`
    margin-top: .4rem;
    color: ${p.theme.neg};
`);