// Imports
// ------------
import React, { useEffect, useRef } from 'react';
import FooterLogo from './Logo';
import Menu from './Menu';
import Icon from '@icons';
import gsap from 'gsap';
import Scrollbar from 'smooth-scrollbar';
import { Row, Column } from '@waffl';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Styles
// ------------
import { Jacket, Content, Copyright, Site, FLJacket } from './styles';

// Component
// ------------
gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    // NOTE • Refs
	const target = useRef();
	const trigger = useRef();

    // NOTE • Dates sholdbe variables for performance - should be made in the pre-render
    const date = new Date().getFullYear();

    // NOTE • Onload - Animate Scroll
    useEffect(() => {
        // NOTE • Variables for scroller
        const scroller = document.querySelector('#scroller');
        const bodyScrollBar = Scrollbar.init(scroller, { damping: 0.1, delegateTo: document, alwaysShowTracks: true });

        // NOTE • Proxy for scroller to pick up smooth scroll
        ScrollTrigger.scrollerProxy(scroller, {
            scrollTop(value) {
                if (arguments.length) {
                    bodyScrollBar.scrollTop = value;
                }
                return bodyScrollBar.scrollTop;
            }
        });

        // NOTE • Actual Transition
        gsap.to(target.current, {
            rotate: `0deg`,
            ease: "none",
            scrollTrigger: {
                scroller: scroller,
                trigger: trigger.current,
                start: "0% 70%",
                end: "50% 70%",
                scrub: 0.5,
                markers: false,
            },
        });

        // NOTE • Move markers to correct position
        if (document.querySelector(".gsap-marker-scroller-start")) {
            const markers = gsap.utils.toArray('[class *= "gsap-marker"]');

            bodyScrollBar.addListener(({ offset }) => {
                gsap.set(markers, { marginTop: -offset.y });
            });
        }

        // NOTE • Update positions & timing
        bodyScrollBar.addListener(ScrollTrigger.update);
    }, []);

    return (
        <Jacket pad ref={trigger}>
            <Row isExpanded isOuter>
                <Column small={12} mpad>
                    <Content>
                        <FooterLogo />

                        <Menu />

                        <Copyright>
                            Copyright &copy; {date} • All rights reserved
                        </Copyright>

                        <Site
                            href="https://www.12studio.agency"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>Site by</span>
                            <Icon type="12" />
                        </Site>
                    </Content>
                </Column>
            </Row>

            <FLJacket ref={target}>
                <FooterLogo isBig />
            </FLJacket>
        </Jacket>
    );
}

export default Footer;