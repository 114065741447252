// Imports
// ------------
import styled, { css } from 'styled-components';
import { Footer, Span } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Footer)`
    position: relative;
    overflow: hidden;
`;

export const Content = styled.div(props => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`);

export const Copyright = styled(Span)(props => css`
    position: relative;
    margin-top: 3.6rem;

    font-family: ${props.theme.heading};
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    opacity: .12;
`);

export const Site = styled.a(props => css`
    position: relative;
    margin-top: 1.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-right: 1.2rem;
        font-family: ${props.theme.heading};
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: uppercase;
    }
`);

export const FLJacket = styled.div`
    position: absolute;
    top: 0; left: 0; right: 0;
    pointer-events: none;

    transform: rotate(-45deg);
`;