// Imports
// ------------
import React from 'react';
import SmoothScroll from '@parts/SmoothScroll';
import Hero from '@parts/Hero';
import Form from '@parts/Contact/Form';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';

// Page
// ------------
const Contact = ({ data }) => (
    <SmoothScroll id="scrollContainer">
        <Hero title="Questions? Contact me" sub="Get in touch" />
        <Form formSuccessMessage={`Thanks for getting in touch, expect a response from me within the next couple of days.`} formErrorMessage={data.global.formErrorMessage} />
        <Footer />
    </SmoothScroll>
);

export default Contact;

// GraphQL
// ------
export const query = graphql`
    query ContactQuery {
        global: datoCmsGlobalSetting {
            formSuccessMessage
            formErrorMessage
        }
	}
`;